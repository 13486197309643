import React from 'react';
import logo from "../../assets/img/gkt_final_logo.png"
import loading from "../../assets/img/loading_img.png";

const Loader = () => {
  return (
    <div className='loader'>
        <img className='loading-logo' width={120} src={logo} alt="logo" />
        <img className='loading-img' src={loading} alt="loading" />
    </div>
  )
}

export default Loader